// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Reval-primary: mat.define-palette(mat.$indigo-palette);
$Reval-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Reval-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Reval-theme: mat.define-light-theme(
  (
    color: (
      primary: $Reval-primary,
      accent: $Reval-accent,
      warn: $Reval-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Reval-theme);

/* You can add global styles to this file, and also import other style files */
$footerHeight: 24px;

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

// TODO: Move this to the typography file in the MIS Component Library
.bold-text {
  font-weight: bold;
}

mis-page {
  padding-bottom: $footerHeight;
}

.loading-panel {
  padding-top: 20px;
  padding-bottom: 20px;
  mis-loading {
    padding-left: 30px;
  }
}

.multiline {
  white-space: pre-line;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
